import { type MouseEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';
import { type HeaderContext } from 'src/client/types';

import { getHideWelcomePopup } from '@jsmdg/browser-storage';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import {
    ClickEventLabel,
    ClickEventType,
    EventCategory,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import {
    Button,
    ButtonColor,
    ButtonShape,
    ButtonSize,
    ButtonVariant,
    CloseIcon,
    ProfileIcon,
    RenderType,
} from '@jsmdg/yoshi';
import { FlyoutBase } from '../FlyoutBase/FlyoutBase';
import styles from './CustomerWelcomeFlyout.module.scss';

enum ActionType {
    Close = 'close',
    Complete = 'complete',
}

type CustomerWelcomeFlyoutProps = {
    readonly onRequestClose: () => void;
};

const messages = defineMessages({
    welcome: {
        defaultMessage: 'Willkommen zurück!',
    },
    description: {
        defaultMessage:
            'Vervollständige {isMD, select, true {Dein} other {dein}} Profil, um alle {account} nutzen zu können',
    },
    accountText: {
        defaultMessage: 'Vorteile des Erlebnis-Kontos',
    },
    buttonLabel: {
        defaultMessage: 'Profil vervollständigen',
    },
    buttonClose: {
        defaultMessage: 'Ausfalter schließen',
    },
});

const CustomerWelcomeFlyout = ({ onRequestClose }: CustomerWelcomeFlyoutProps): JSX.Element => {
    const intl = useIntl();

    const { isMydays } = useFragmentContext<HeaderContext>();

    const onButtonClick = async (e: MouseEvent, buttonType: ActionType): Promise<void> => {
        e.preventDefault();
        const isCompleteAction = buttonType === ActionType.Complete;
        getHideWelcomePopup().set('true');
        await window.yieldToMainThread();

        trackAnalyticsEvent({
            category: EventCategory.Header,
            action: ClickEventType.BUTTON_CLICK,
            label: ClickEventLabel.COMPLETE_PROFILE,
            nonInteraction: false,
            eventData: {
                eventName: GA4EventName.ClickButton,
                feature_category: GA4FeatureCategory.HeaderNavigation,
                click_element: isCompleteAction
                    ? 'Complete account profile'
                    : 'Close account profile completion popup',
                click_text: isCompleteAction ? 'Profil vervollständigen' : 'X',
            },
        });

        onRequestClose();

        if (isCompleteAction) {
            window.location.assign('/account#profile');
        }
    };

    return (
        <FlyoutBase onRequestClose={onRequestClose} dataTestId="customer-account-welcome-flyout">
            <div className="d-flex justify-content-end align-items-end p-0-5x">
                <Button
                    variant={ButtonVariant.Ghost}
                    shape={ButtonShape.Icon}
                    size={ButtonSize.Small}
                    color={ButtonColor.Dark}
                    onClick={async (e: MouseEvent) => onButtonClick(e, ActionType.Close)}
                    a11yText={intl.formatMessage(messages.buttonClose)}
                    dataTestId="close-button"
                >
                    <CloseIcon />
                </Button>
            </div>

            <div className="p-2x pt-0">
                <div className="d-flex justify-content-center align-items-center">
                    <span
                        className={classNames(
                            'd-flex justify-content-center align-items-center',
                            styles.profileIcon,
                        )}
                    >
                        <ProfileIcon size={38} />
                    </span>
                </div>
                <h2 className="text-center my-2x p-0">{intl.formatMessage(messages.welcome)}</h2>
                <p className={classNames('text-center mb-2x', styles.description)}>
                    {intl.formatMessage(messages.description, {
                        account: <b>{intl.formatMessage(messages.accountText)}</b>,
                        isMD: isMydays,
                    })}
                </p>
                <Button
                    href="/account#profile"
                    onClick={async (e: MouseEvent) => onButtonClick(e, ActionType.Complete)}
                    variant={ButtonVariant.Outlined}
                    as={RenderType.Link}
                    size={ButtonSize.Responsive}
                    color={ButtonColor.Brand}
                    className="text-center my-1x w-100"
                    dataTestId="complete-button"
                >
                    {intl.formatMessage(messages.buttonLabel)}
                </Button>
            </div>
        </FlyoutBase>
    );
};

export { CustomerWelcomeFlyout };
// @loadable/component has an issue with the named export which we couldn't resolve so far.
// When using named exports it fails on the second render
// Using a default export for now until we found solution.
export default CustomerWelcomeFlyout; // eslint-disable-line import/no-default-export
